/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import fb from "../../content/assets/icon_fb.png"
import li from "../../content/assets/icon_li.png"
import insta from "../../content/assets/icon_in.png"
import yt from "../../content/assets/icon_yt.png"


function SocialLinks() {
    return (
        <section className="social-links">
            <a href='https://www.facebook.com/studioalbatross/' target="_blank">
                <img src={fb} alt="Facebook" className="social-link-icon" />
            </a>
            <a href='https://www.linkedin.com/company/studio-albatross' target="_blank">
            <img src={li} alt="LinkedIn"  className="social-link-icon" />
            </a>
            <a href='https://www.instagram.com/studioalbatross/' target="_blank">
            <img src={insta} alt="Instagram"  className="social-link-icon" />
            </a>
            <a href='https://www.youtube.com/channel/UCsq8eKAxqpsPrU5QxPFCPUQ' target="_blank">
            <img src={yt} alt="YouTube"  className="social-link-icon" />
            </a>
        </section>
    )
}



export default SocialLinks
