import React from "react"
import { Link } from "gatsby"

import SocialLinks from "../components/socialLinks"

import logo from "../../content/assets/logo.png"
import "../utils/css/components/fullpage-menu.sass"
import "../utils/css/components/animate.css"



class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.toggleState = this.toggleState.bind(this);
        this.state = {
            toggleNav: false
         };

         
    }

    componentDidMount() {
        this.content = "aassdfsdf";
        
    }

    scrollPage() {
       //alert('scroll');
        // window.scrollTo(window.innerHeight);
    }

    toggleState(){
        console.log('toggle');
        this.setState({
            toggleNav: !this.state.toggleNav
          });
    }

    // const [toggleNav, setToggleNav] = useState(false);

    render() {
        const { title, children } = this.props;
        
        return (

            <div className="site-wrapper">
            
            <header className="site-head">
                <div className="site-head-container">
                    <div className="site-head-left">&nbsp;</div>

                    <div className={`site-head-center ${this.state.toggleNav ? 'hide' : ''}`}>
                            {/* <Link className="site-head-logo" to={`/`}>
                                <img src={logo} alt="Logo" />
                            </Link> */}
                    </div>
                    <div className="site-head-right">
                       
                        <a
                            className={`nav-burger ${this.state.toggleNav ? 'site-head-open' : ' '}`}
                            href={`#`}
                            onClick={this.toggleState}
                        >
                            <div
                                className="hamburger hamburger--collapse"
                                aria-label="Menu"
                                role="button"
                                aria-controls="navigation"
                            >
                                <div className="hamburger-box">
                                    <div className="hamburger-inner" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </header>
            <div className={`fullpage-nav ${this.state.toggleNav ? 'show' : ''}`}>
                <div className="fullpage-nav-content">
                    <nav >
                        <ul className="nav" role="menu">
                            <li className="nav-home" role="menuitem">
                                <Link to={`/`} onClick={this.toggleState}>Home</Link>
                            </li>
                            <li className="nav-about" role="menuitem">
                                <Link to={`/about`} onClick={this.toggleState}>About Us</Link>
                            </li>
                            <li className="nav-divs" role="menuitem">
                                <Link to={`/films`} onClick={this.toggleState}>Films</Link><span className="dots">&nbsp;·&nbsp;</span><Link to={`/design`} onClick={this.toggleState}>Design</Link><span className="dots">&nbsp;·&nbsp;</span><Link to={`/marketing`} onClick={this.toggleState}>Marketing</Link>
                            </li>
                            <li className="nav-work" role="menuitem">
                                <a href="https://www.studioalbatross.in/projects/" onClick={this.toggleState}>Our Work</a>
                                {/* <Link to={`/work`} onClick={this.toggleState}>Our Work</Link> */}
                            </li>
                            <li className="nav-contact" role="menuitem">
                                <Link to={`/contact`} onClick={this.toggleState}>Start A New Project</Link>
                            </li>
                            {/* <li className="nav-contact" role="menuitem">
                            <a href="https://www.studioalbatross.in/kanboard/" onClick={this.toggleState}>Client Login</a>

                            </li> */}
                        </ul>
                    </nav>
                    <div className="nav-info">
                        <img src={logo} alt="Logo" />
                        <a href="mailTo:sales@studioalbatross.in">sales@studioalbatross.in</a>
                        <a href="tel:+918983387110">+91 8983 387 110</a>
                        <a href="https://www.studioalbatross.in/kanboard/" title="Client Login" onClick={this.toggleState} className="nav-info-link">Client Login</a>
                        <SocialLinks></SocialLinks>
                    </div>
                </div>
            </div>
            <main id="site-main" className="site-main">
                <div  className="transition-fade">
                    {children}
                </div>
            </main>
        </div>


        );
    }
}

export default Layout;