import React from "react"
import { Link } from "gatsby"
import SocialLinks from "../components/socialLinks"
import Fade from 'react-reveal/Fade'

import "../utils/css/components/contact.css"
import arrow from "../../content/assets/arrow-right.svg"



export default contactCard => (
    <section className="section section-flex contact-section">
        {/* <div className="empty"></div> */}
        <div className="mid">
        <Fade bottom>
            <div className="contact-card">
                <div className="contact-information">
                    <Fade delay={200}>
                        <div className="section-title">
                            Join the Flight
                    </div>
                    </Fade>
                    <Fade bottom cascade delay={300}>
                        <div className="contact-links">
                            <a href="mailTo:sales@studioalbatross.in">sales@studioalbatross.in</a>
                            <a href="tel:+918983387110">+91 8983 387 110</a>
                            <div className="follow-us">FOLLOW US</div>
                            <SocialLinks></SocialLinks>
                        </div>
                    </Fade>
                </div>


                <Link to={`/contact`} className="new-project">
                    <Fade delay={500}>
                        <span>START A NEW PROJECT WITH US</span>
                        <img className="arrow" src={arrow} alt="Start" />
                    </Fade>
                </Link>


            </div>
        </Fade>
        </div>
        <Fade bottom>
        <footer className="site-foot">
                &copy; {new Date().getFullYear()} <Link to={`/`}>Albatross</Link> &mdash;
        Made by Studio Albatross
            </footer> 
            </Fade>
    </section>
)
